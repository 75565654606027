import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from 'services';

export const useLoginState = () => {
  const queryClient = useQueryClient();
  const { data, error = false } = useQuery('isLogin', () =>
    API.get('/admin/current_admin')
  );

  const loginUserMutation = useMutation(
    (userData) => API.post('/admins/sign_in', userData),
    { onSuccess: () => queryClient.invalidateQueries('isLogin') }
  );

  const logoutUserMutation = useMutation(
      () => API.delete('/admins/sign_out'),
      { onSuccess: () => queryClient.invalidateQueries('isLogin') }
  );

  return [
    { user: data, isLogin: error ? false : true },
    { loginUserMutation, logoutUserMutation }
  ];
};
