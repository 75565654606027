import { useState } from "react"
import { DataGrid } from "@material-ui/data-grid"
import { makeStyles } from "@material-ui/core/styles";
import { BasicLayout } from "components/layouts/BasicLayout"
import { useCollectibles } from "hooks/useCollectibles"
import { useHistory } from "react-router-dom"
import {FormGroup, FormControlLabel, Switch, TextField, Grid} from "@material-ui/core"

export const Collectibles = props => {
    const history = useHistory()
    const [page, setPage] = useState(1)
    const [filter, setFilter] = useState({ s: 'created_at desc' });
    const [search, setSearch] = useState();
    const { isLoading, data: response } = useCollectibles({ page, filter, search })

    const handleRowClick = ({ id }) => {
        history.push(`/collectibles/${id}`)
    }

    const handleSortChange = (sortModel) => {
        const order = sortModel[0]?.sort || 'desc';
        setFilter({...filter, s: `created_at ${order}`});
    };

    const handleOnlyBannedSwitchChange = (event) => {
        const checked = event.target.checked;
        setFilter({ ...filter, banned_eq: checked ? true : null });
        setPage(1);
    };

    const handleOnlyAbusedSwitchChange = (event) => {
      const checked = event.target.checked;
        setFilter({ ...filter, has_abuses: checked ? true : null });
        setPage(1);
    };

    const handleSearch = (event) => {
        if (event.key === 'Enter') {
            setSearch(event.target.value);
            setPage(1);
        }
    };
    const classes = useStyles();

    return (
        <BasicLayout>
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <FormControlLabel label="Only banned"  control={
                    <Switch color="primary" onChange={handleOnlyBannedSwitchChange} />
                  }/>
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel label="Have abuse"  control={
                    <Switch color="primary" onChange={handleOnlyAbusedSwitchChange} />
                  }/>
                </Grid>
                <Grid item xs={6}></Grid>
              </Grid>
                <TextField
                    fullWidth
                    label="Search"
                    variant="outlined"
                    margin="dense"
                    onKeyPress={handleSearch}
                />
            </FormGroup>
            <div style={{ height: "80%", minHeight: "600px" }}>
                <DataGrid
                    loading={isLoading}
                    className={classes.root}
                    columns={columns}
                    rows={response?.collectibles || []}
                    pageSize={20}
                    page={page - 1}
                    rowCount={response?.pagy.count}
                    disableSelectionOnClick
                    disableColumnMenu
                    onPageChange={page => setPage(page + 1)}
                    onRowClick={handleRowClick}
                    onSortModelChange={handleSortChange}
                    sortingMode="server"
                    paginationMode="server"
                    getRowClassName={(params) =>
                      `collectible-row-theme--${params.getValue(params.id, 'abuses_presence') ? 'Abused' : 'Open'}`
                    }
                />
            </div>
        </BasicLayout>
    )
}
const imageStyle = {
    width: "100%",
    padding: "3px",
    height: "100%",
    objectFit: "cover",
}

const ImageCell = params => <img src={params.value} alt="" style={imageStyle} />
const BannedCell = (params) => {
    return <Switch disabled checked={params.value}/>;
}
const DateCell = params => new Date(params.value).toLocaleDateString()

const columns = [
    {
        field: "banned",
        headerName: "banned",
        width: 90,
        renderCell: BannedCell,
        editable: false,
        filterable: true,
        sortable: false,
    },
    {
        field: "preview_url",
        headerName: "Preview",
        width: 120,
        renderCell: ImageCell,
        sortable: false,
        editable: false,
        filterable: false
    },
    {
        field: "title",
        headerName: "Title",
        width: 250,
        sortable: false,
        filterable: false
    },
    {
        field: "media_provider",
        headerName: "Media Provider",
        width: 100,
        sortable: false,
        filterable: false
    },
    {
        field: "user_name",
        headerName: 'User',
        width: 200,
        sortable: false,
        filterable: false
    },
    {
        field: "created_at",
        headerName: "Created",
        renderCell: DateCell,
        width: 110,
    },
]

const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        '& .collectible-row-theme--Abused': {
          backgroundColor: "#F44336",
          '&:hover': {
            backgroundColor: "#E57373",
          },
        },
      },
    };
  },
  {},
);