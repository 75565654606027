import { Box, Button, Grid, Icon } from "@material-ui/core"
import { DataGrid } from "@material-ui/data-grid"
import { BasicLayout } from "components/layouts/BasicLayout"
import { useSingleCollectible, useCollectibleAbuses } from "hooks/useCollectibles"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

export const SingleCollectible = ({ match: { params } }) => {
    const { data } = useCollectibleAbuses(params.id)
    const abuses = data?.abuses || [];

    return (
        <BasicLayout>
            <Box my={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}>
                    <RowItem id={params.id} />
                </Grid>
            </Box>
            <div style={{ height: "80%", minHeight: "600px" }}>
                <h3>Abuses</h3>
                <DataGrid
                    columns={columns}
                    rows={abuses}
                    disableSelectionOnClick
                    disableColumnMenu
                    loading={!abuses}
                    // onRowClick={handleRowClick}
                />
            </div>
        </BasicLayout>
    )
}

const RowItem = ({ id }) => {
    const { data } = useSingleCollectible(id)
    const history = useHistory()
    const { handleSubmit } = useForm()
    const { updateCollectibleMutation } = useSingleCollectible(id)

    const handleUpdateCollectible = async () => {
        await updateCollectibleMutation.mutateAsync({ banned: !data.banned })
    }

    return data ? (
        <>
            <Grid item md={12}>
                <h3>{data.title}</h3>
            </Grid>

            {/*<Grid item md={12}>*/}
            {/*    <img*/}
            {/*        src={data.preview_url}*/}
            {/*        alt="nft"*/}
            {/*        style={{ objectFit: "cover", width: "100%", height: "100%" }}*/}
            {/*    />*/}
            {/*</Grid>*/}

            <Grid item>
                <form onSubmit={handleSubmit(handleUpdateCollectible)}>
                <Button type="submit" variant="contained" color={data.banned? 'red' : 'primary'}>
                    <span>{data.banned ? 'Unban' : 'Ban'}</span>
                </Button>
                </form>
            </Grid>
            <Grid item>
                User: <b>{data.user_name}</b>
            </Grid>
            <Grid item>
                <datetime>
                    {new Date(data.created_at).toLocaleDateString()}
                </datetime>
            </Grid>
            <Grid item md={4} container justifyContent="flex-end">
                <Button onClick={history.goBack}>
                    <Icon>arrow_left</Icon> Go back
                </Button>
            </Grid>
        </>
    ) : null
}

const DateCell = params => new Date(params.value).toLocaleDateString()
const UserCell = params => params.value.name || params.value.id;

const columns = [
    {
        field: "id",
        headerName: "ID",
        width: 100
    },
    {
      field: "user",
        headerName: "User",
        renderCell: UserCell,
        width: 110,
    },
    {
        field: "message",
        headerName: "Message",
        width: 400,
        sortable: false
    },
    {
        field: "created_at",
        headerName: "Created",
        renderCell: DateCell,
        width: 110,
    },
]
