import { useState } from "react"
import { BasicLayout } from "components/layouts/BasicLayout"
import { useForm } from "react-hook-form"
import { useSettings } from "hooks/useSettings"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TableHead, Table, TableRow, TableCell, TableBody, Input
} from "@material-ui/core"

export const Settings = props => {
  const [open, setOpen] = useState(false);
  const [changedRow, setChangedRow] = useState({});
  const { register, handleSubmit } = useForm()
  const { query, updateSettingMutation } = useSettings()
  const { data: response } = query || {};
  const data = response || [];

  const handleRowClick = (row) => {
    setChangedRow(row);
    handleClickOpen();
  }

  const handleUpdate = async form => {
    await updateSettingMutation.mutateAsync(form)
    handleClose();
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setChangedRow({});
    setOpen(false);
  };

  return (
    <BasicLayout>
      <div style={{ height: "80%", minHeight: "600px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Key</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(({ key, value }) => {
              return (
                <TableRow onClick={() => handleRowClick({ key, value })}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>

      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Update setting value"}
        </DialogTitle>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Input defaultValue={changedRow.value} {...register(`settings[${changedRow.key}]`)}></Input>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              color="primary"
              type="submit"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </BasicLayout>
  )
}
