import { useState } from "react"
import { DataGrid } from "@material-ui/data-grid"
import { BasicLayout } from "components/layouts/BasicLayout"
import { useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useMasterAccounts, useMasterAccount } from "hooks/useMasterAccounts"
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, TextField } from "@material-ui/core"

export const MasterAccounts = props => {
    const history = useHistory()
    const [page, setPage] = useState(1)
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState();
    const { data: response } = useMasterAccounts({page: page, search: search})
    const data = response;
    const { register, handleSubmit } = useForm()

    const { createMasterAccountMutation } = useMasterAccount(page)

    const handleRowClick = ({ id }) => {
      history.push(`/master-accounts/${id}`)
    }

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleCreate = async form => {
      await createMasterAccountMutation.mutateAsync(form)
      handleClose();
    }

    const handleSearch = (event) => {
      if (event.key === 'Enter') {
          setSearch(event.target.value);
          setPage(1);
      }
    };

    return (
        <BasicLayout>
          <Button 
            variant="outlined" 
            color="primary"
            onClick={handleClickOpen}
            >Create master account</Button>
          <FormGroup>
                <TextField
                    fullWidth
                    label="Search"
                    variant="outlined"
                    margin="dense"
                    onKeyPress={handleSearch}
                />
            </FormGroup>
            <div style={{ height: "80%", minHeight: "600px" }}>
                <DataGrid
                    columns={columns}
                    rows={Object.values(data?.master_accounts || {})}
                    pageSize={20}
                    page={page - 1}
                    rowCount={data?.pagy.count}
                    disableSelectionOnClick
                    disableColumnMenu
                    onPageChange={page => setPage(page + 1)}
                    onCellClick={handleRowClick}
                    sortingMode="server"
                    paginationMode="server"
                />
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Create master account"}
              </DialogTitle>
              <form onSubmit={handleSubmit(handleCreate)}>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={12}> 
                      <TextField
                        fullWidth
                        required
                        label="Phone number"
                        placeholder="Phone number"
                        variant="outlined"
                        {...register("phone_number")}
                      />
                    </Grid>
                    <Grid item xs={6} md={12}>
                      <TextField
                        fullWidth
                        required
                        label="Name"
                        placeholder="Name"
                        variant="outlined"
                        {...register("name")}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    type="submit"
                    color="primary">
                    Save
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
        </BasicLayout>
    )
}

const DateCell = params => new Date(params.row.created_at).toLocaleDateString()

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 310,
    filterable: false,
    sortable: false
  },
  {
    field: "phone_number",
    headerName: "Phone number",
    width: 150,
    filterable: false,
    sortable: false
  },
  {
    field: "name",
    headerName: "Nickname",
    width: 240,
    filterable: false,
    sortable: false
  },
  {
    field: "created_at",
    headerName: "Created",
    renderCell: DateCell,
    width: 200,
    sortable: false
  }
]
