export const API_ENDPOINT = process.env.REACT_APP_API_ROUTE || 'https://api2.hypefactory.app/api/v1';

const defaultConfig = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  }
};
const _API = async (url, config = defaultConfig) => {
  if (config.body instanceof FormData) {
    delete config.headers['Content-Type'];
  } else {
    config.body = JSON.stringify(config.body);
  }
  const response = await fetch(`${API_ENDPOINT + url}`, config);

  if (!response.ok) {
    const error = new Error('Something went wrong on fetch :(');
    error.info = await response.json();
    error.status = response.status;
    const trace = error.info.errors?.join('');
    error.message = trace;
    throw error;
  }

  const text = await response.text();

  return text.length ? JSON.parse(text) : response;
};

export const API = {
  get(url, query, config) {
    return _API(url, { method: 'GET', ...defaultConfig });
  },
  post(url, data, config) {
    return _API(url, { method: 'POST', body: data, ...defaultConfig });
  },
  patch(url, data, config) {
    return _API(url, { method: 'PATCH', body: data, ...defaultConfig });
  },
  delete(url, config) {
    return _API(url, { method: 'DELETE', ...defaultConfig });
  }
};
