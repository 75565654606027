import {
  Grid,
  Box,
  Button,
  Container,
  Icon,
  makeStyles,
} from "@material-ui/core"
import { Logo } from "components/common/Logo"
import { Link } from "react-router-dom"
import { useLoginState } from "hooks/useLoginState"

export const BasicLayout = ({ children }) => {
  const classes = useStyles()
  const [, { logoutUserMutation }] = useLoginState();

  return (
    <Container maxWidth="lg" style={{ paddingBottom: "30px" }}>
      <Box px={2} py={1} my={5}>
        <Grid container component="header" justifyContent="space-between">
          <Grid
            item
            xs={12}
            justifyContent="center"
            alignContent="center"
            container
            sm={2}>
            <Link to="/">
              <Logo />
            </Link>
          </Grid>
          <Grid
            item
            container
            sm={10}
            spacing={1}
            classes={{
              root: classes.headerControls,
            }}>
            <Grid item>
              <Button color="primary" onClick={() => logoutUserMutation.mutate()}>
                <span>Log out</span>
                <Icon className={classes.marginLeft}>logout</Icon>
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
          <Grid item xs={1}>
            </Grid>
            <Grid item xs={2}>
              <Link to="/">
                <span>Collectibles</span>
              </Link>
            </Grid>
            <Grid item xs={1}>
              <Link to="/users">
                <span>Users</span>
              </Link>
            </Grid>
            <Grid item xs={3}>
              <Link to="/master-accounts">
                <span>Master Accounts</span>
              </Link>
            </Grid>
            <Grid item xs={3}>
              <Link to="/settings">
                <span>Settings</span>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {children}
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  headerControls: {
    margin: theme.spacing(2, 0, 2, 0),
    justifyContent: 'flex-end',
    [theme.breakpoints.only("xs")]: {
      justifyContent: "center",
      "& button>span>span+span": {
        display: "none",
      },
      "& button>span>span": {
        margin: 0,
      },
    },
  },
}))
