import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from 'services';

export const useSettings = () => {
  const apiURL = '/admin/app_config';
  const query = useQuery(['app_config'], () => API.get(apiURL), {
    keepPreviousData: true
  });

  const queryClient = useQueryClient();
  const updateSettingMutation = useMutation(
    ['app_config'],
    (data) => API.patch(`/admin/app_config`, data),
    {
      onSuccess: () => queryClient.invalidateQueries('app_config')
    }
  );

  return { query, updateSettingMutation };
};
