import { Box, Button, Grid, Icon, Link } from "@material-ui/core"
import { DataGrid } from "@material-ui/data-grid"
import { BasicLayout } from "components/layouts/BasicLayout"
import { useSingleMasterAccount } from "hooks/useMasterAccounts"
import { useHistory } from "react-router-dom"

export const SingleMasterAccount = ({ match: { params } }) => {
    const { data } = useSingleMasterAccount(params.id)
    const users = data?.users || [];

    return (
        <BasicLayout>
            <Box my={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}>
                    <RowItem id={params.id} />
                </Grid>
            </Box>
            <div style={{ height: "80%", minHeight: "600px" }}>
                <h3>Users</h3>
                <DataGrid
                    columns={columns}
                    rows={users}
                    disableSelectionOnClick
                    disableColumnMenu
                    loading={!users}
                    sortingMode="server"
                />
            </div>
        </BasicLayout>
    )
}

const RowItem = ({ id }) => {
    const { data } = useSingleMasterAccount(id)
    const history = useHistory()

    return data ? (
        <>
            <Grid item md={12}>
                <h3>{data.master_account.name}</h3>
            </Grid>
            <Grid item>
            </Grid>
            <Grid item>
                Phone number: <b>{data.master_account.phone_number}</b>
            </Grid>
            <Grid item>
                Created at: <b>
                  <datetime>
                    {new Date(data.master_account.created_at).toLocaleDateString()}
                  </datetime>
                </b>
            </Grid>
            <Grid item md={4} container justifyContent="flex-end">
                <Button onClick={history.goBack}>
                    <Icon>arrow_left</Icon> Go back
                </Button>
            </Grid>
        </>
    ) : null
}

const CreationPermission = params => {
  const permissionStatuses = {
    forbidden: "Forbidden",
    external_only: "External only",
    all_available: "All available"
  }
  return (permissionStatuses[params.value])
};

const SocialLinks = params => {
  const sweeterArray = params.value.map(sweetItem => {
    return (
      <div style={{ marginRight: "8px" }}>
        <Link href={sweetItem.profile_url}>
          {sweetItem.provider}
        </Link>
      </div>
    )
  })
  return sweeterArray
}

const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      sortable: false
    },
    {
      field: "name",
      headerName: "Name",
      width: 120,
      sortable: false
    },
    {
      field: "phone_number",
      headerName: "Phone number",
      width: 150,
      sortable: false
    },
    {
      field: "easynft_address",
      headerName: "Wallet address",
      width: 400,
      filterable: false,
      sortable: false
    },
    {
      field: "creation_permission",
      headerName: "Creation permission",
      renderCell: CreationPermission,
      width: 130,
      sortable: false,
      filterable: false
    },
    {
      field: "profile_urls",
      headerName: "Social links",
      width: 300,
      renderCell: SocialLinks,
      sortable: false,
      filterable: false
    }
]
