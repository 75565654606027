import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from 'services';
import qs from 'qs';

export const useCollectibles = (opts) => {
  const stUrl = qs.stringify({ page: opts.page, q: opts.filter, search: opts.search }, { encode: false })
  const apiURL = `/admin/collectibles?${stUrl}`;
  const query = useQuery([`collectibles`, stUrl], () => API.get(apiURL));
  return { ...query };
};
export const useSingleCollectible = (collectibleId) => {
  const queryClient = useQueryClient();

  const query = useQuery([`collectibles`, collectibleId], () =>
    API.get(`/admin/collectibles/${collectibleId}`)
  );

  const updateCollectibleMutation = useMutation(
    [`/admin/collectibles/`, collectibleId],
    (data) => API.patch(`/admin/collectibles/${collectibleId}`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`collectibles`)
      }
    }
  );

  return { ...query, updateCollectibleMutation };
};

export const useCollectibleAbuses = (collectibleId) => {
  const query = useQuery([`/admin/collectibles/abuses`, collectibleId], () =>
      API.get(`/admin/collectibles/${collectibleId}/abuses`)
  );

  return { ...query }
};