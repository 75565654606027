import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from 'services';
import qs from 'qs';

export const useUsers = (opts) => {
  const stUrl = qs.stringify({ page: opts.page, search: opts.search }, { encode: false })
  const apiURL = opts.page ? `/admin/users?${stUrl}` : '/admin/users';
  const query = useQuery([`users`, stUrl], () => API.get(apiURL), {
    keepPreviousData: true
  });
  return { ...query };
};
export const useSingleUser = (userId, page) => {
  const queryClient = useQueryClient();

  // const query = useQuery([`/admin/users/`, userId], () =>
  //   API.get(`/admin/users/${userId}`)
  // );

  const updateUserMutation = useMutation(
    [`/admin/users/`, userId],
    (data) => API.patch(`/admin/users/${userId}`, data),
    {
      onSuccess: () => queryClient.invalidateQueries('users')
    }
  );

  return { updateUserMutation };
};
