import { Box, Button, Grid, makeStyles, TextField } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import { Logo } from "components/common/Logo"
import { useLoginState } from "hooks/useLoginState"
import { useForm } from "react-hook-form"
import { Redirect, useHistory } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& .MuiInputBase-root": {
      marginBottom: theme.spacing(3),
    },
    "& h2": {
      margin: theme.spacing(6),
      textTransform: "uppercase",
    },
  },
  button: {
    width: "60%",
    margin: "0 auto",
  },
}))

export const Login = props => {
  const [{ isLogin }, { loginUserMutation }] = useLoginState()
  const { register, handleSubmit } = useForm()
  const history = useHistory()
  const classes = useStyles()

  const handleLogin = async form => {
    form = { admin: form }
    await loginUserMutation.mutateAsync(form)
    history.push("/")
  }

  if (isLogin) return <Redirect to="/" />
  return (
    <Container>
      <Box
        className={classes.wrapper}
        display="flex"
        flexDirection="column"
        sm={12}
        alignItems="center">
        <Logo />
        <h2>Sign in</h2>
        <form onSubmit={handleSubmit(handleLogin)}>
          <Grid container spacing={2}>
            <TextField
              fullWidth
              required
              label="E-mail"
              placeholder="email"
              variant="outlined"
              {...register("email")}
            />
            <TextField
              fullWidth
              required
              type="password"
              label="Password"
              placeholder="password"
              variant="outlined"
              {...register("password")}
            />
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}>
              Sign in
            </Button>
          </Grid>
        </form>
      </Box>
    </Container>
  )
}
