import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Collectibles, Login, SingleCollectible, Users, MasterAccounts, SingleMasterAccount, Settings } from 'pages';
import './App.css';
import { useLoginState } from 'hooks/useLoginState';
import { QUERY_CONFIG } from 'config/queryClient';

const queryClient = new QueryClient(QUERY_CONFIG);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/sign-in" component={Login} />
          <ProtectedRoute exact path="/" component={Collectibles} />
          <ProtectedRoute exact path="/collectibles/:id" component={SingleCollectible} />
          <ProtectedRoute exact path="/users" component={Users} /> 
          <ProtectedRoute exact path="/master-accounts" component={MasterAccounts} /> 
          <ProtectedRoute exact path="/master-accounts/:id" component={SingleMasterAccount} /> 
          <ProtectedRoute exact path="/settings" component={Settings} />
        </Switch>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

const ProtectedRoute = ({ component: Component, ...props }) => {
  const [{ isLogin }] = useLoginState();

  return (
    <Route
      {...props}
      render={(props) =>
        isLogin ? <Component {...props} /> : <Redirect to="/sign-in" />
      }
    />
  );
};

export default App;
