import { useState } from "react"
import { DataGrid } from "@material-ui/data-grid"
import { BasicLayout } from "components/layouts/BasicLayout"
import { useForm } from "react-hook-form"
import { useUsers, useSingleUser } from "hooks/useUsers"
import { FormControl, Select, Button, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Link, FormGroup, TextField, Switch, FormControlLabel, Grid } from "@material-ui/core"

export const Users = props => {
    const [page, setPage] = useState(1)
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState();
    const [changedRow, setChangedRow] = useState({});
    const { register, handleSubmit } = useForm()
    const { data: response } = useUsers({page: page, search: search})
    const data = response;

    const { updateUserMutation } = useSingleUser(changedRow.id, page)

    const handleRowClick = ({ field, row }) => {
      if(field === 'verified') {
        setChangedRow(row);
        handleClickOpen();
      } else if (field === 'id') {
        window.location.href = `https://app.funrise.io/profile/${row.nickname || row.easynft_address}`;
      }
    }

    const handleUpdate = async form => {
      await updateUserMutation.mutateAsync(form)
      handleClose();
    }

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setChangedRow({});
      setOpen(false);
    };

    const handleSearch = (event) => {
      if (event.key === 'Enter') {
          setSearch(event.target.value);
          setPage(1);
      }
  };

    return (
        <BasicLayout>
          <FormGroup>
                <TextField
                    fullWidth
                    label="Search"
                    variant="outlined"
                    margin="dense"
                    onKeyPress={handleSearch}
                />
            </FormGroup>
            <div style={{ height: "80%", minHeight: "600px" }}>
                <DataGrid
                    columns={columns}
                    rows={Object.values(data?.users || {})}
                    pageSize={20}
                    page={page - 1}
                    rowCount={data?.pagy.count}
                    disableSelectionOnClick
                    disableColumnMenu
                    onPageChange={page => setPage(page + 1)}
                    onCellClick={handleRowClick}
                    sortingMode="server"
                    paginationMode="server"
                />
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Change creation permission"}
              </DialogTitle>
              <form onSubmit={handleSubmit(handleUpdate)}>                
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}> 
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 450 }}>
                        <Select
                          labelId={`creation-permission-select-label-${changedRow.id}`}
                          id={`creation-permission-select-${changedRow.id}`}
                          defaultValue={changedRow.creation_permission}
                          label="Creation permission"
                          {...register("creation_permission")}
                        >
                          <MenuItem value={"forbidden"}>Forbidden</MenuItem>
                          <MenuItem value={"external_only"}>External only</MenuItem>
                          <MenuItem value={"all_available"}>All available</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <FormControlLabel label="Verified"  control={
                        <Switch 
                          color="primary"
                          defaultChecked={changedRow.verified}
                          {...register("verified")}
                        />
                      }/>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <FormControlLabel label="Allow promo"  control={
                        <Switch
                          color="primary"
                          defaultChecked={changedRow.allowed_create_promo_campaigns}
                          {...register("allowed_create_promo_campaigns")}
                        />
                      }/>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    color="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
        </BasicLayout>
    )
}

const SocialLinks = params => {
  const sweeterArray = params.value.map(sweetItem => {
    return (
      <div style={{ marginRight: "8px" }}>
        <Link href={sweetItem.profile_url}>
          {sweetItem.provider}
        </Link>
      </div>
    )
  })
  return sweeterArray
}
const CreationPermission = params => {
  const permissionStatuses = {
    forbidden: "Forbidden",
    external_only: "External only",
    all_available: "All available"
  }
  return (permissionStatuses[params.value])
};

const VerifiedCell = (params) => {
  return <Switch disabled checked={params.value}/>;
}

const columns = [
  {
      field: "id",
      headerName: "ID",
      width: 90,
      filterable: false,
      sortable: false,
  },
  {
    field: "phone_number",
    headerName: "Phone number",
    width: 120,
    filterable: false,
    sortable: false
  },
  {
      field: "verified",
      headerName: "Verified",
      renderCell: VerifiedCell,
      width: 130,
      sortable: false,
      filterable: false
  },
  {
      field: "nickname",
      headerName: "Nickname",
      width: 120,
      filterable: false,
      sortable: false,
  },
  {
    field: "country_name",
    headerName: "Country",
    width: 150,
    filterable: false,
    sortable: false,
  },
  {
    field: "easynft_address",
    headerName: "Wallet address",
    width: 400,
    filterable: false,
    sortable: false,
  },
  {
      field: "creation_permission",
      headerName: "Creation permission",
      renderCell: CreationPermission,
      width: 130,
      sortable: false,
      filterable: false
  },
  {
      field: "profile_urls",
      headerName: "Social links",
      width: 300,
      renderCell: SocialLinks,
      sortable: false,
      filterable: false
    }
]
