import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from 'services';
import qs from 'qs';

export const useMasterAccounts = (opts) => {
  const stUrl = qs.stringify({ page: opts.page, q: {phone_number_cont: opts.search} }, { encode: false })
  const apiURL = opts.page ? `/admin/master_accounts?${stUrl}` : '/admin/master_accounts';
  const query = useQuery([`master_accounts`, stUrl], () => API.get(apiURL), {
    keepPreviousData: true
  });
  return { ...query };
};

export const useSingleMasterAccount = (masterAccountId) => {
  
  const query = useQuery([`master_accounts`, masterAccountId], () =>
    API.get(`/admin/master_accounts/${masterAccountId}`)
  );

  return { ...query };
};

export const useMasterAccount = (page) => {
  const queryClient = useQueryClient();

  const createMasterAccountMutation = useMutation(
    [`/admin/master_accounts`, page],
    (data) => API.post('/admin/master_accounts', data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries('master_accounts');  
      }
    }
  );

  return { createMasterAccountMutation };
};
